<template>
  <v-card ma-5>
    <v-card-title class="headline sdark">
      <v-layout row>
        <v-flex>
          <span
            class="primary--text text-uppercase font-weight-medium"
          >DroneShield End User License Agreement (2024)</span>
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" icon flat @click.native="$emit('close')">
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <p>
        <span>
          <span>
            THE PRODUCTS ARE PROVIDED ONLY ON THE CONDITION THAT END USER AGREES
            TO THE TERMS AND CONDITIONS IN THIS END USER LICENSE AGREEMENT AND
            THE MATERIALS REFERENCED HEREIN (“EULA”) BETWEEN END USER AND
            DRONESHIELD
          </span>
        </span>
        <span>
          <span>CORPORATION PTY LTD</span>
        </span>
        <span>
          <span>
            (“DRONESHIELD”). BY ACCEPTING THIS AGREEMENT OR BY USING THE
            PRODUCTS, END USER ACKNOWLEDGES IT HAS READ, UNDERSTANDS, AND AGREES
            TO BE BOUND BY THIS AGREEMENT.
          </span>
        </span>
      </p>
      <p />
      <p>1. Definitions.</p>
      <p />
      <p>
        <span>
          <span>“Documentation”</span>
        </span>
        <span>
          <span>
            means the Product installation instructions, user manuals, setup
            posters, release notes, and operating instructions prepared by
            DroneShield, in any form or medium, as may be updated from time to
            time by DroneShield and made generally available to End User.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“End User”</span>
        </span>
        <span>
          <span>
            means the individual, company, or other legal entity that has placed
            an Order, is the ultimate end user of the Products.
          </span>
        </span>
        <span>
          <span></span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“Error”</span>
        </span>
        <span>
          <span>
            means a material failure of the Product to conform to the
            Documentation, which is reported by End User and replicable by
            DroneShield.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“</span>
        </span>
        <span>
          <span>Hardware</span>
        </span>
        <span>
          <span>” means</span>
        </span>
        <span>
          <span> DroneShield’s proprietary hardware products identified on an Order.</span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“Maintenance”</span>
        </span>
        <span>
          <span>
            means the annual support services and updates as provided by
            DroneShield as published and announced on its website.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“Order”</span>
        </span>
        <span>
          <span>
            means a purchase commitment mutually agreed upon between (1)
            DroneShield and End User or (2) a DroneShield authorized reseller(s)
            and End User.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“Products”</span>
        </span>
        <span>
          <span>
            means Hardware and Software, updates, upgrades, together with
            applicable Documentation.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“Software”</span>
        </span>
        <span>
          <span>
            means DroneShield’s proprietary software applications, in object
            code only.
          </span>
        </span>
        <span>
          <span></span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“Subscription”</span>
        </span>
        <span>
          <span>
            means a non-exclusive, nontransferable right to use the Products in
            accordance with this EULA and the Order. This only applies to the
            Products sold on a Subscription basis.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“Subscription Fees”</span>
        </span>
        <span>
          <span>
            means the fees as agreed in writing for this sale. This only applies
            to the Products sold on a paid Subscription basis.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>“Subscription Term”</span>
        </span>
        <span>
          <span>
            means the agreed upon time period in an Order, renewed on annual
            basis by payment of Annual Subscription (as per Exhibit A) by the
            End User to DroneShield. This only applies to the Products sold on a
            paid Subscription basis (currently, RF signature database).
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>2. Product Subscription.</span>
        </span>
        <span>
          <span>
            Subject to the provisions contained in this EULA, and timely payment
            of the applicable fees (annually, upfront), DroneShield hereby
            grants End User, for the Subscription Term, a Subscription to use
            the Products identified in the Order. This only applies to the
            Products sold on a Subscription basis (currently, RF signature
            database).
          </span>
        </span>
      </p>
      <p />
      <p>3. End User Obligations.</p>
      <p />
      <p>
        <span>
          <span>3.1</span>
        </span>
        <span>
          <span>
            End User will comply with all applicable laws, statutes, regulations
            and ordinances and only use the Products for legitimate business
            purposes.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>3.2</span>
        </span>
        <span>
          <span>
            End User will defend, indemnify and hold DroneShield harmless
            against any loss, damage or costs (including reasonable attorneys'
            fees) incurred in connection with any claims, demands, suits, or
            proceedings (“Claims”) made or brought against DroneShield by a
            third party alleging or related to End User’s (i) violation of its
            obligations in this EULA; (ii) civil or criminal offenses; (iii)
            transmission or posting of obscene, indecent, or pornographic
            materials; (iv) transmission or posting of any material which is
            slanderous, defamatory, offensive, abusive, or menacing or which
            causes annoyance or needless anxiety to any other person; and (v)
            violations of applicable law.
          </span>
        </span>
      </p>
      <p />
      <p>4. Support Services.</p>
      <p />
      <p>
        <span>
          <span>4.1</span>
        </span>
        <span>
          <span>
            “Support Services” as described herein are provided free of charge
            during the Limited Warranty period, pursuant to Sections 4.2 and 4.3
            herein.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>4.2</span>
        </span>
        <span>
          <span>
            Product technical support includes (i) standard technical support,
            via phone or email consultation or by qualified 3rd party
            reseller/partner services, including Error corrections or
            workarounds so that the Products operate in substantial conformance
            with the Documentation, and (ii) the provision of Software update
            and upgrades, excluding updates to DroneShield’s signature database,
            if and when available, all of which are provided as “Support
            Services” under DroneShield’s then-current Support Services
            policies. Standard technical support includes online website and
            portal access, and telephone support during business hours. RF
            database updates and Software upgrades will be provided to End User
            only if End User has paid the appropriate Subscription Fees.
            DroneShield may require End User to install Software upgrades up to
            and including the latest release.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>4.3</span>
        </span>
        <span>
          <span>
            DroneShield’s obligation to provide Support Services is limited to:
            (i) a Product that has not been altered or modified by anyone other
            than DroneShield; (ii) a release for which Support Services is
            provided; (iii) End User’s use of the Product in accordance with the
            Documentation; and (iv) errors and malfunctions caused by systems or
            programs supplied by DroneShield. If an Error has been corrected or
            is not present in a more current version of the Product, DroneShield
            will provide the more current version via Support Services, but will
            not have any obligation to correct such Error in prior versions.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>5. Intellectual Property Rights.</span>
        </span>
        <span>
          <span>
            The Products and all related intellectual property rights are the
            exclusive property of DroneShield. All right, title and interest in
            and to the Products, any modifications, translations, or derivatives
            thereof, even if unauthorized, and all applicable rights in patents,
            copyrights, trade secrets, trademarks and all intellectual property
            rights in the Products remain exclusively with DroneShield. The
            Products are valuable, proprietary, and unique, and End User agrees
            to be bound by and observe the proprietary nature of the Products.
            The Products contain material that is protected by patent, copyright
            and trade secret law, and by international treaty provisions. The
            Products may include software products licensed from third parties.
            Such third parties have no obligations or liability to End User
            under this EULA but are third party beneficiaries of this EULA. All
            rights not granted to End User in this EULA are reserved to
            DroneShield. DroneShield may make changes to the Products at any
            time without notice. DroneShield grants no express or implied right
            under DroneShield patents, copyrights, trademarks, or other
            intellectual property rights. End User may not remove any
            proprietary notice of DroneShield or any third party from the
            Products or any copy of the Products, without DroneShield’s prior
            written consent.
          </span>
        </span>
      </p>
      <p />
      <p>6. License Restrictions</p>
      <p />
      <p>
        <span>
          <span>6.1</span>
        </span>
        <span>
          <span>
            DroneShield may disclose to the End User certain confidential
            technical and business information which DroneShield desires the End
            User to treat as confidential. "Confidential Information" means any
            information disclosed, in writing, orally, electronically or by
            inspection of tangible objects (including without limitation
            documents, prototypes, equipment, technical data, trade secrets and
            know-how, product plans, Products, services, suppliers, customer
            lists and customer information, prices and costs, markets, software,
            databases, developments, inventions, processes, formulas,
            technology, employee information, designs, drawings, engineering,
            hardware configuration information, marketing, licenses, finances,
            budgets and other business information), which is designated as
            "Confidential," "Proprietary" or some similar designation at or
            prior to the time of disclosure, or which should otherwise
            reasonably be considered confidential by the End User. End User
            agrees that all Confidential Information will be treated as
            non-public confidential information and will not be disclosed to any
            person and that End User will protect the confidentiality of such
            Confidential Information in the same manner that it protects the
            confidentiality of its own proprietary and confidential information,
            but in no event with less than a reasonable standard of care.
            Furthermore, the End User agrees to only use the Confidential
            Information of the other party for purposes of carrying out its
            rights and obligations under this EULA.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>6.2</span>
        </span>
        <span>
          <span>
            End User will not itself, or through any affiliate, employee,
            consultant, contractor, agent or other third party: (i) sell,
            resell, distribute, host, lease, rent, license or sublicense, in
            whole or in part, the Products
          </span>
        </span>
        <span>
          <span>
            or engage in the manufacture of any other products itself or through
            third parties using or containing the Confidential Information
          </span>
        </span>
        <span>
          <span>
            ; (ii) decipher, decompile, disassemble, reverse assemble, modify,
            translate, reverse engineer or otherwise attempt to derive source
            code, algorithms, tags, specifications, architecture, structure or
            other elements of the Products, in whole or in part, for competitive
            purposes or otherwise; (iii) allow access to, provide, divulge or
            make available the Products to any user other than End User’s
            employees and individual contractors who have a need to such access
            and who shall be bound by nondisclosure obligations that are at
            least as restrictive as the terms of this EULA; (iv) write or
            develop any derivative works based upon the Products; (v) modify,
            adapt, translate or otherwise make any changes to the Products or
            any part thereof; (vi) use the Products to provide processing
            services to third parties, or otherwise use the same on a ‘service
            bureau’ basis; (vii) disclose or publish, without DroneShield’s
            prior written consent, performance or capacity statistics or the
            results of any benchmark test performed on the Products; (viii)
            otherwise use or copy the same except as expressly permitted herein;
            or (ix) use any third party software included in the Products
            independently from the Products.
          </span>
        </span>
        <span>
          <span></span>
        </span>
      </p>
      <p />
      <p>7. Financial Terms.</p>
      <p />
      <p>
        <span>
          <span>7.1</span>
        </span>
        <span>
          <span>
            Fees and payment terms are specified in the applicable Order. Except
            as otherwise expressly specified in the Order, all payment
            obligations start from the execution of the Order. DroneShield may
            increase product pricing at any time upon 90 days written notice.
            Interest accrues on past due balances at the lesser of (i)
            DroneShield’s then current late fees, or (ii) the highest rate
            allowed by law. Failure to make timely payments is a material breach
            of the EULA and DroneShield will be entitled to suspend any or all
            of its performance obligations hereunder in accordance with the
            provisions of Section 12 and to modify the payment terms, including
            requiring full payment before DroneShield performs any obligations
            under this EULA. End User will reimburse DroneShield for any
            expenses incurred, including interest and reasonable attorney fees,
            in collecting amounts due DroneShield hereunder that are not under
            good faith dispute by End User. End User is responsible for payment
            of all taxes (excluding those on DroneShield's net income) arising
            out of this EULA, except to the extent a valid tax exemption
            certificate or other written documentation acceptable to DroneShield
            to evidence End User’s tax exemption status is provided by End User
            to DroneShield prior to the delivery of Products or services.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>7.2</span>
        </span>
        <span>
          <span>
            All Orders are non-cancellable once they have been accepted by
            DroneShield, and there shall be no fee adjustments or refunds for
            any reason, including decreases in usage, or otherwise. End User
            agrees that its purchases hereunder are neither contingent on the
            delivery of any future functionality or features nor dependent on
            any oral or written comments made by DroneShield regarding future
            functionality or features.
          </span>
        </span>
      </p>
      <p />
      <p>8. Limited Warranty; Remedies; Disclaimer.</p>
      <p />
      <p>
        <span>
          <span>8.1</span>
        </span>
        <span>
          <span>
            For a period not to exceed one (1) year from shipment (unless an 
            extended warranty is purchased), DroneShield warrants that the 
            Products, as updated from time to time by DroneShield and used in 
            accordance with the Documentation and the EULA by End User, will 
            operate in substantial conformance with the Documentation under 
            normal use. End User acknowledges that the Product is innovative 
            and as such is not foolproof, will not operate without interruption 
            or error-free, and may experience certain operational challenges 
            that are beyond DroneShield’s control. DroneShield does not warrant 
            and End User acknowledges that (A) the Products will (i) be free of 
            defects, (ii) satisfy End User’s requirements or are adapted to End 
            User’s needs, or (iii) operate in combination with any other software 
            or hardware which End User may use, or (iv) operate without 
            interruption or error.
          </span>
        </span>
        <span>
          <span>
            End User further acknowledges that (i) the prices and other charges
            contemplated under this Agreement are based on the limited warranty,
            disclaimer and limitation of liability specified herein and (ii)
            such charges would be substantially higher if any of these
            provisions were unenforceable. For the avoidance of doubt, this
            warranty applies only to the initial delivery of Product under an
            Order and does not renew or reset, for example, with any software or
            Product updates.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>8.2</span>
        </span>
        <span>
          <span>
            End User must promptly notify DroneShield in writing of a claim that
            an Error exists. Provided that such claim is reasonably determined
            by DroneShield to be DroneShield’s responsibility, DroneShield
            shall, within thirty (30) days of its receipt of End User’s written
            notice, (i) correct the Error or provide a workaround; (ii) provide
            End User with a plan reasonably acceptable to End User for
            correcting the Error; or (iii) if neither (i) nor (ii) can be
            accomplished with reasonable commercial efforts from DroneShield at
            DroneShield’s discretion, then DroneShield may terminate the
            affected Product Subscription (if applicable) and End User will be
            not be entitled to a refund of the unused Subscription Fees paid for
            the balance of the then-current Subscription Term (or the price paid
            for the Product, if Subscription does not apply) unless required by
            applicable local laws. This paragraph sets forth End User’s sole and
            exclusive remedy and DroneShield's entire liability for any breach
            of warranty or other duty related to the Products.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>8.3</span>
        </span>
        <span>
          <span>
            The warranties and remedies specified in this Section 8 will not
            apply
          </span>
        </span>
        <span>
          <span>
            and DroneShield is not obligated to provide technical support if the
            Product malfunctions are due to extrinsic causes, such as (i)
            natural disasters, including fire, smoke, water, earthquakes or
            lightning, (ii) electrical power fluctuations or failures or
            electrochemical influences, including as a result of DroneShield’s
            use of the cloud, (iii) the neglect or misuse of the Products or
            other failure to comply with the instructions set forth in the
            applicable user Documentation, (iv) a correction or modification of
            the Products not provided by DroneShield or End User’s failure to
            use any new or corrected versions of the Product made available by
            DroneShield, (v) the failure to properly and promptly install the
            Products, (vi) a malfunction of the End User’s hardware equipment,
            or (vii) the combination or use of the Products with other software
            or equipment not provided, or approved by DroneShield. In addition,
            the foregoing limited warranty shall not apply if, and End User
            shall be solely responsible in the event that, End User combines
            functionality of the Products with End User’s or other third party
            technology and makes any warranties that are inconsistent with and
            contrary to those published by DroneShield. Further, this warranty
            shall not be valid if the Products have been subject to misuse,
            unauthorized modification, improper installation or if End User is
            not in compliance with this Agreement.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>8.4</span>
        </span>
        <span>
          <span>
            THE WARRANTIES SET FORTH IN THIS SECTION 8 ARE IN LIEU OF, AND
            DRONESHIELD AND ITS SUPPLIERS EXPRESSLY DISCLAIM TO THE MAXIMUM
            EXTENT PERMITTED BY LAW, ALL OTHER WARRANTIES, EITHER EXPRESS OR
            IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, NON-INFRINGEMENT, TITLE OR FITNESS FOR A PARTICULAR
            PURPOSE, AND FREEDOM FROM PROGRAM ERRORS, VIRUSES OR ANY OTHER
            MALICIOUS CODE WITH RESPECT TO THE PRODUCTS AND SERVICES PROVIDED
            UNDER THIS AGREEMENT.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>9. Limitation of Liability.</span>
        </span>
        <span>
          <span>
            NOTWITHSTANDING ANYTHING IN THIS AGREEMENT TO THE CONTRARY,
            DRONESHIELD, ITS AFFILIATES, OR RESELLERS WILL NOT BE LIABLE FOR ANY
            INDIRECT,
          </span>
        </span>
        <span>
          <span>
            CONSEQUENTIAL, SPECIAL, PUNITIVE OR INCIDENTAL DAMAGES, WHETHER
            FORESEEABLE OR UNFORESEEABLE, ARISING OUT OF OR RELATED TO THIS
            AGREEMENT (INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR LOSS OF DATA,
            GOODWILL, USE OF MONEY OR USE OF THE PRODUCTS, LATE DELIVERY,
            INTERRUPTION IN USE OR AVAILABILITY OF DATA, STOPPAGE OF OTHER WORK
            OR IMPAIRMENT OF OTHER ASSETS, OR DAMAGE OR LOSS CREATED BY
            DISRUPTION TO COMMUNICATIONS FROM THE USE OF DRONE COUNTERMEASURES),
            ARISING OUT OF BREACH OR FAILURE OF EXPRESS OR IMPLIED WARRANTY,
            BREACH OF CONTRACT, MISREPRESENTATION, NEGLIGENCE, STRICT LIABILITY
            IN TORT OR OTHERWISE. IN NO EVENT WILL THE AGGREGATE LIABILITY WHICH
            DRONESHIELD MAY INCUR IN ANY ACTION OR PROCEEDING EXCEED THE TOTAL
            AMOUNT ACTUALLY RECEIVE BY DRONESHIELD FOR THE SPECIFIC ITEM THAT
            DIRECTLY CAUSED THE DAMAGE OVER THE ONE YEAR PERIOD PRIOR TO THE
            EVENT OUT OF WHICH THE CLAIM AROSE FOR THE PRODUCT THAT DIRECTLY
            CAUSED THE DAMAGE. THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS SET
            FORTH IN THIS SECTION 9 WILL NOT APPLY ONLY IF AND TO THE EXTENT
            THAT A COURT OF COMPETENT JURISDICTION REQUIRES LIABILITY UNDER
            APPLICABLE LAW BEYOND AND DESPITE THESE LIMITATIONS, EXCLUSIONS AND
            DISCLAIMERS.
          </span>
        </span>
      </p>
      <p />
      <p>10. Indemnification.</p>
      <p />
      <p>
        <span>
          <span>10.1</span>
        </span>
        <span>
          <span>
            The End User agrees to indemnify and hold harmless DroneShield, its
            employees and managers against any claims, loss, judgments, damages,
            costs (including reasonable lawyers’ fees and disbursements) and
            settlements agreed to by End User arising out of or in relation to:
            (a) the use of any Product under this EULA, in a manner not
            expressly permitted by DroneShield under this EULA; (b) the failure
            of End User to comply with any provision of this EULA; (c) any claim
            with respect to privacy concerns, including, but not limited to, the
            audio recording and audio noises inadvertently recorded by the
            Products and stored in a DroneShield cloud, in connection with the
            use of the Product; and (d) any infringement claim arising from or
            in connection with: (i) modifications to the Product where such
            modification is made by the End User, or on behalf of the End User,
            (ii) the End User’s combined use of the Product and/or Support
            Services or portion thereof with applications not supplied by
            DroneShield or in any way inconsistent with the Product’s intended
            use; provided however, that in relation to any of (a) -(d): (i) End
            User is promptly notified in writing of such claim, (ii) End User
            shall have the sole control of the defense and/or settlement
            thereof, (iii) DroneShield furnishes to End User on request any
            information in DroneShield’s possession or control for such defense,
            (iv) DroneShield will not admit any such claim and/or make any
            payments with respect to such claim without the prior written
            consent of End User, and (v) DroneShield shall, at End User’s
            expense, cooperate with, and provide such assistance to End User in
            the defense of such claims as reasonably requested by End User.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>11. Usage.</span>
        </span>
        <span>
          <span>
            The End User acknowledges and understands that DroneShield’s drone
            detection equipment is hosted through a third party cloud service
            provider and as such the End User (i) consents to the flow of his,
            her or its data through the cloud service used by DroneShield, and
            (ii) acknowledges that he she or it may experience loss of data or
            interruption in service, including complete outages, and his or her
            information may otherwise be comprised or breached, due to the
            cloud. The End User shall not hold DroneShield liable in any
            capacity for any loss of data, data that has been compromised or
            breached by third parties, or interruptions in service caused by the
            cloud. End User acknowledges that the cloud is located in several
            geographic locations and as such, End User consents to the flow of
            its data and information through several foreign countries as
            DroneShield deems necessary.
          </span>
        </span>
      </p>
      <p />
      <p>
        End User further acknowledges that the Product utilizes audio sensors
        that may have the ability to unintentionally record conversations and
        background noise and information which are automatically uploaded to a
        DroneShield cloud where it is stored. Such ability and outcome are not
        within the understood purpose of the Product. End User agrees to use the
        Products for only their intended purpose and explicitly consents to the
        recording of its conversations and any noise in the surrounding area
        where the Products are located and the uploading of such audio files to
        a DroneShield cloud.
      </p>
      <p />
      <p>12.Term and Termination; Survival.</p>
      <p />
      <p>
        <span>
          <span>12.1</span>
        </span>
        <span>
          <span>
            This EULA continues in full force and effect until the later of (i)
            expiration or termination of the Subscription Term (if applicable),
            and (ii) the completion of all paid services by DroneShield, unless
            otherwise terminated earlier as provided hereunder. Upon termination
            or expiration of the Subscription Term, End User’s right to use the
            Products terminates. The preceding sentence notwithstanding,
            Sections 3, 4.3, 5, 6, 7.3, 8, 9, 10, 11, 13, 14 and 15 shall
            survive the termination of this EULA indefinitely.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>12.2</span>
        </span>
        <span>
          <span>
            Either party may terminate this EULA immediately upon written notice
            at any time if: (i) the other party commits a non-remediable
            material breach of the EULA, or if the other party fails to cure any
            remediable material breach or provide a written plan of cure
            acceptable to the non-breaching party within thirty (30) days of
            being notified in writing of such breach, except for breach of
            payment terms which shall have a ten (10) day cure period; or (ii)
            the other party ceases business operations; or (iii) the other party
            becomes insolvent, generally stops paying its debts as they become
            due or seeks protection under any bankruptcy, receivership, trust
            deed, creditors arrangement, composition or comparable proceeding,
            or if any such proceeding is instituted against the other (and not
            dismissed within 90 days after commencement of one of the foregoing
            events); provided, however, that DroneShield may terminate this EULA
            and cease all services hereunder upon ninety (90) days written
            notice. Upon notification of termination by either party,
          </span>
        </span>
        <span>
          <span>
            End User must uninstall any Products, cease using and destroy or
            return all copies of the Products to DroneShield, and to certify in
            writing that all known copies thereof, including backup copies, have
            been destroyed. Sections 1 and 6-15 shall survive the termination of
            this EULA.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>12.3</span>
        </span>
        <span>
          <span> Section 12.2 notwithstanding,</span>
        </span>
        <span>
          <span></span>
        </span>
        <span>
          <span>
            DroneShield shall be entitled to suspend any or all services upon
            then (10) days written notice to End User in the event End User is
            in breach of this EULA. DroneShield may impose an additional charge
            to reinstate service following suspension.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>13. Government Restricted Rights.</span>
        </span>
        <span>
          <span>
            The Products were developed exclusively at private expense and are
            commercial items, as defined in the Federal Acquisition Regulation
            (“FAR”). Accordingly, pursuant to FAR 12.211, 12.212 and DFARS
            227.7202, use, duplication and disclosure of the Products to, by, or
            for the U.S. Government (including, but not limited to, all
            branches, agencies, departments, and all other governmental
            entities) or a U.S. Government contractor or subcontractor is
            controlled by and subject solely to the terms and conditions set
            forth in this Agreement, which shall supersede any conflicting terms
            or conditions in any government order document.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>
            To the extent a specific Product does not qualify as a commercial
            item, it still has the status of being developed exclusively at
            private expense and the governmental entity End User shall obtain no
            more than “LIMITED RIGHTS” or “RESTRICTED RIGHTS” in such Product as
            defined in
          </span>
        </span>
        <span>
          <span>FAR 52.227-14 and DFAR 252.227-7013 et seq. or its successor.</span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>14. Export.</span>
        </span>
        <span>
          <span>
            The Products are subject to export controls of the United States and
            other countries (“Export Controls”). Export or diversion contrary to
            U.S. law is prohibited. U.S. law prohibits export or re-export of
            the Products or technology to specified countries or to a resident
            or national of those countries (“Prohibited Country” or “Prohibited
            Countries”). It also prohibits export or re-export of the Products
            or technology to any person or entity on the U.S. Department of
            Commerce Denied Persons List, Entities List or Unverified List; the
            U.S. Department of State Debarred List; or any of the lists
            administered by the U.S. Department of Treasury, including lists of
            Specially Designated Nationals, Specially Designated Terrorists or
            Specially Designated Narcotics Traffickers (collectively, the
            “Lists”). U.S. law also prohibits use of the Product or technology
            with chemical, biological or nuclear weapons, or with missiles
            (“Prohibited Uses”). End User represents and warrants that it is not
            located in, or a resident or national, of any Prohibited Country;
            that it is not on any Lists; that it will not use the Product or
            technology for any Prohibited Uses; and that it will comply with
            Export Controls.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>15. Compliance with Laws.</span>
        </span>
        <span>
          <span>
            End User shall comply with all applicable laws and regulations,
            including privacy laws and regulations having application to or
            governing its use and operation of the Products. End User shall
            indemnify and hold DroneShield harmless from and against any claims,
            damages, losses or obligations suffered or incurred by DroneShield
            arising from End User’s failure to so comply. End User shall at all
            times conduct its activities in accordance with all applicable laws
            related to anti-bribery or anti-corruption legislation including,
            but not limited to, the U.S. Foreign Corrupt Practices Act of 1977
            and all national, state, provincial or territorial anti-bribery and
            anti-corruption statutes. Accordingly, End User shall make no offer,
            payment or gift, will not promise to pay or give, and will not
            authorize, directly or indirectly, the promise or payment of, any
            money or anything of value to any DroneShield employee or agent, any
            government official, any political party or its officials, or any
            person while knowing or having reason to know that all or a portion
            of such money or item of value will be offered, given or promised
            for the purpose of influencing any decision or act to assist End
            User or DroneShield or otherwise obtaining any improper advantage or
            benefit.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>16. Force Majeure.</span>
        </span>
        <span>
          <span>
            Neither party will be liable for any failure or delay in performing
            an obligation under this EULA that is due to causes beyond its
            reasonable control, such as natural catastrophes, governmental acts
            or omissions, laws or regulations, labor strikes or difficulties,
            transportation stoppages or slowdowns or the inability to procure
            parts or materials. These causes will not excuse End User from
            paying accrued amounts due to DroneShield through any available
            lawful means acceptable to DroneShield. If any of these causes
            continue to prevent or delay performance for more than ninety (90)
            days, DroneShield may terminate this Agreement, effective
            immediately upon notice to End User.
          </span>
        </span>
      </p>
      <p />
      <p>
        <span>
          <span>17. General.</span>
        </span>
        <span>
          <span>
            For the purposes of customer service, technical support, and as a
            means of facilitating interactions with its end-users, End User
            acknowledges and consents to DroneShield periodically sending End
            User messages of an informational or advertising nature via email,
            and providing account information to related third-parties (e.g. End
            User’s reseller). End User may choose to “opt-out” of receiving
            these messages or information sharing by sending an email to
            info@droneshield.com requesting the opt-out. End User acknowledges
            and agrees that by sending such email and “opting out” it will not
            receive emails containing messages concerning upgrades and
            enhancements to Products. However, DroneShield may still send emails
            of a technical nature. DroneShield owns any suggestions, ideas,
            enhancement requests, feedback, or recommendations provided by End
            User relating to the Products. DroneShield may use non-identifying
            and aggregate usage and statistical information related to End
            User’s and its users’ use of the Products for its own purposes
            outside of the EULA. End User may not transfer any of End User’s
            rights to use the Products or assign this EULA to another person or
            entity, without first obtaining prior written approval from
            DroneShield. Any notice required or permitted under this EULA or
            required by law must be in writing and must be (i) delivered in
            person, (ii) sent by first class registered mail, return receipt
            requested, or air mail, as appropriate, or (iii) sent by an
            internationally recognized overnight air courier, in each case
            properly posted and fully prepaid. Notices sent to DroneShield must
            be sent to
          </span>
        </span>
        <span>
          <span>
            DroneShield Corporation Pty Ltd, Level 5, 126 Phillip Street, SYDNEY NSW 
            2000, 
          </span>
        </span>
        <span>
          <span>Australia</span>
        </span>
        <span>
          <span>
            . Notices are considered to have been given at the time of actual
            delivery in person, five (5) business days after deposit in the mail
            as set forth above, or one (1) day after delivery to an overnight
            air courier service. Either party may change its contact person for
            notices and/or address for notice by means of notice to the other
            party given in accordance with this paragraph. Any dispute arising
            out of or relating to this EULA or the breach thereof shall be
            governed by the federal laws of the United States and the laws of
            the State of New York, without regard to or application of choice of
            laws, rules or principles. Both parties hereby consent to the
            exclusive jurisdiction of the state and federal courts in New York
            County, New York, for all claims arising in or related to the United
            States. Both parties expressly waive any objections or defense based
            upon lack of personal jurisdiction or venue. Neither party will be
            liable for any delay or failure in performance to the extent the
            delay or failure is caused by events beyond the party’s reasonable
            control, including, fire, flood, acts of God, explosion, war or the
            engagement of hostilities, strike, embargo, labor dispute,
            government requirement, civil disturbances, civil or military
            authority, disturbances to the Internet, and inability to secure
            materials or transportation facilities. This EULA constitutes the
            entire agreement between the parties regarding the subject matter
            herein and the parties have not relied on any promise,
            representation, or warranty, express or implied, that is not in this
            EULA. Any waiver or modification of this EULA is only effective if
            it is in writing and signed by both parties
          </span>
        </span>
        <span>
          <span>.</span>
        </span>
        <span>
          <span>
            All pre-printed or standard terms of any End User’s purchase order
            or other business processing document have no effect. In the event
            of a conflict between the terms of this EULA and the terms of an
            Order, the terms of this EULA prevail. If any part of this EULA is
            found invalid or unenforceable by a court of competent jurisdiction,
            the remainder of this EULA shall be interpreted so as reasonably to
            affect the intention of the parties. DroneShield is not obligated
            under any other agreements unless they are in writing and signed by
            an authorized representative of DroneShield.
          </span>
        </span>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'LicenseCard'
}
</script>
